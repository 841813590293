<template>
	<div class="container">
		<div class="title">
			<span class="inner_title">停车场二维码创建</span>
		</div>
		<div>
			<div class="item-box">
				<span class="item-left">生成类型：</span>
				<el-select v-model="type" placeholder="请选择" @change="changeType">
					<el-option v-for="(item,index) in typeList" :key="item.key" :value="item.key" :label="item.value"></el-option>
				</el-select>
			</div>
			<div class="item-box">
				<span class="item-left">停车场：</span>
				<el-select v-model="parking_no" placeholder="请选择" @change="getExit">
					<el-option v-for="(item,index) in parkList" :key="item.id" :value="item.s_no" :label="item.s_name"></el-option>
				</el-select>
			</div>
			<div class="item-box" v-if="type == 1">
				<span class="item-left">通道口：</span>
				<el-select v-model="exit" placeholder="请选择" @change="getExitNo">
					<el-option v-for="(item,index) in exitList" :key="item.eae_id" :value="item.eae_id" :label="item.eae_title"></el-option>
				</el-select>
			</div>
			<div class="item-box" v-if="exit">
				<span class="item-left"></span>
				<el-radio-group v-model="exit_no" @change="getExitType">
					<el-radio v-for="(item,index) in exitNoList" :key="item.eae_num" :label="item.eae_num"> {{item.name}}</el-radio>
				</el-radio-group>
			</div>
			<div class="item-box" v-if="can_show">
				<span class="item-left"></span>
				<el-button type="primary" @click="addErCode()">生成</el-button>
			</div>
			<div class="item-box" v-if="!can_show">
				<span class="item-left">二维码信息：</span>
				<img src="../../assets/ok.png" width="40px" height="40px" />
				<span>&nbsp;二维码生成成功</span>
			</div>
			<div class="item-box" v-if="!can_show">
				<span class="item-left"></span>
				<img :src="imgUrl" />
				<!-- <el-button style="margin-left: 100px;" type="primary" @click="download(imgUrl)">下载</el-button> -->
				<el-button style="margin-left: 50px;" type="primary"><a :href="base64" target="_blank" :download="imgUrl">下载</a></el-button>
				<el-button style="margin-left: 50px;" type="warning" @click="clearClick">清空</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		// Message,
		MessageBox
	} from 'element-ui';
	import {
		getErCodeFn,
		getImgBaseFn,
		getTypeFn,
		addErCodeFn,
		getParkNumFn,
		getExitFn,
		saveErCodeFn,
	} from '../../api/parking.js'
	import {
		getXiaoQuDetailsFn
	} from '../../api/xiaoQu.js'
	export default {
		props: [],
		data() {
			return {
				id: '',
				type: '',
				parking_no: '',
				parking_name: '',
				exit: '',
				exit_no: '',
				exit_name: '',
				exit_type: '',
				can_show: true,
				imgUrl: '',
				base64: '',
				typeList: [],
				parkList: [],
				exitList: [],
				exitNoList: [],
				qr_id: '',
			};
		},
		mounted() {
			this.getId(),
				this.getType(),
				this.getParkNum()
		},
		methods: {
			getErCode() {
				if (this.type == 1) {
					getErCodeFn({
						com_id: 2,
						scene: 'qr_id=' + this.qr_id,
						page: 'packageCar/pages/chuKouJiaoFei/chuKouJiaoFei', //packageCar/pages/chuKouJiaoFei/chuKouJiaoFei
						width: 220,
					}).then(res => {
						if (res.code == 200) {
							this.can_show = false
							this.imgUrl = res.datas.url
							this.saveErCode()
							this.getImgBase()
						}
					})
				}
				if (this.type == 2) {
					getErCodeFn({
						com_id: 2,
						scene: 'qr_id=' + this.qr_id,
						page: 'packageCar/pages/chePai/chePai', //pages/home/indexdemo
						width: 220,
					}).then(res => {
						if (res.code == 200) {
							this.can_show = false
							this.imgUrl = res.datas.url
							this.saveErCode()
							this.getImgBase()
						}
					})
				}
			},
			//获取小区id
			getId() {
				getXiaoQuDetailsFn({
					api_token: localStorage.getItem('token1'),
				}).then(res => {
					if (res.code == 200) {
						this.id = res.datas.village_id
						console.log(this.id)
					}
				})
			},
			//获取二维码分类
			getType() {
				getTypeFn().then(res => {
					if (res.code == 200) {
						this.typeList = res.datas
					}
				})
			},
			//获取停车场列表
			getParkNum() {
				getParkNumFn({
					api_token: localStorage.getItem('token1')
				}).then(res => {
					if (res.code == 200) {
						this.parkList = res.datas
					}
				})
			},
			//获取通道列表
			getExit(e) {
				this.parkList.map((val, index) => {
					if (e == val.s_no) {
						this.parking_name = val.s_name
						console.log(this.parking_name)
					}
				})
				getExitFn({
					api_token: localStorage.getItem('token1'),
					s_no: e
				}).then(res => {
					if (res.code == 200) {
						this.exitList = res.datas
						console.log(this.exitList)
					}
				})
			},
			//获取进出口
			getExitNo(e) {
				console.log(e)
				this.exitList.map((val, index) => {
					if (e == val.eae_id) {
						this.exitNoList = val.list;
						this.exit_name = val.eae_title
					}
				})
				this.exitNoList.map((val, index) => {
					if (val.eae_try == 1) {
						this.exitNoList[index]['name'] = "进口"
					}
					if (val.eae_try == 2) {
						this.exitNoList[index]['name'] = "出口"
					}
				})
			},
			//获取进出口
			getExitType(e) {
				this.exitNoList.map((val, index) => {
					if (e == val.eae_num) {
						this.exit_type = val.eae_try
						console.log(this.exit_type)
					}
				})
			},
			//新增二维码
			addErCode() {
				if (!this.type) {
					this.$message('请选择生成类型！');
					return
				}
				if (!this.parking_no) {
					this.$message('请选择停车场！');
					return
				}
				if(this.type == 1){
					if(!this.exit){
						this.$message('请选择通道口！');
						return
					}
					if(!this.exit_no){
						this.$message('请选择通道口类型！');
						return
					}
				}
				addErCodeFn({
					api_token: localStorage.getItem('token1'),
					type: this.type,
					s_no: this.parking_no,
					s_name: this.parking_name,
					eae_title: this.exit_name,
					eae_num: this.exit_no,
					eae_try: this.exit_type
				}).then(res => {
					if (res.code == 200) {
						this.qr_id = res.datas.qr_id
						this.getErCode()
					}
				})
			},
			//监听类型
			changeType(){
				this.parking_no = '';
				this.exit = '';
				this.exit_no = '';
			},
			//保存二维码
			saveErCode() {
				saveErCodeFn({
					api_token: localStorage.getItem('token1'),
					qr_id: this.qr_id,
					img: this.imgUrl
				}).then(res => {
					if (res.code == 200) {
						this.$message({
							message: "新增成功",
							type: 'success'
						});
					}
				})
			},
			//清空
			clearClick() {
				this.can_show = true;
				this.type = '';
				this.parking_no = '';
				this.parking_name = '';
				this.exit = '';
				this.exit_no = '';
				this.exit_name = '';
				this.exit_type = '';
				this.imgUrl = '';
				this.base64 = '';
				this.qr_id = '';
			},
			//图片下载
			getImgBase() {
				getImgBaseFn({
					img_url: this.imgUrl
				}).then(res => {
					if (res.code == 200) {
						this.base64 = res.datas.image_content
					}
				})
			}
		},
	};
</script>
<style scoped>
	@import url("../../style/detail.css");
</style>
